import React from 'react'
import Layout from '../components/Layout'
import {Location} from '@reach/router'
import Content from '../components/Content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-solid-svg-icons';
const testimonials = [
    {
        name:'Liz A',
        content:()=>(
            <>
                <p>We are so pleased with our alarm system. After seeing this ad, we called David Andrews & he came out the next day. We felt so comfortable with him right away. He knows what he’s doing & he helped us out. This past Saturday he came out & got us connected. <strong>He was thorough, kind and very knowledgeable.</strong> He offers good quality equipment and I love all of the add-ons you can get to truly customize your alarm needs. He offers you advice without pressuring you, which is what I liked. My husband and I recommend him with no reservations.</p>
            </>
        )
    },{
        name:'Carol I.',
        content:()=>(
            <>
            <p>Thank you Dave, your <strong>professionalism, honesty and kindness</strong> made it no brainer to go with you and your company for alarm systems</p>
            </>
        )
    },{
        name:'Nikia J.',
        content:()=>(
            <>
            <p>
            David has done it once again. What a pleasure to do with him on an alarm Install on my new home. I decided to go with David and his company after being an ADT customer for 13 years. David is honest and professional. <strong>He actually listened to my needs rather than utilize aggressive sales tactics to try to get this most money he could.</strong> I am much happier with the upgraded system I now have in my home. If you are looking for alarm services please give David a call.
            </p>
            </>
        )
    },
    {
        name:'Henrique R.',
        content:()=>(
           <>
           <p>Hey everyone! I recently got an alarm system installed and I’m really happy with the installation and the service. <strong>Very professional, affordable the technician was polite and put no pressure.</strong> The equipment is high quality and David is very Knowledgeable. His number is 407 318 6752 and his name is David Andrews with US Alarms in Clermont.
            </p>
           </>
        )
    }
]
export default () => (  
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                   <Content>
                       {
                           testimonials && testimonials.map((t,i)=>{
                                return (
                                    <div className="text-center" key={`testimonial_${i}`}>
                                        <div className="col">
                                            <div className="star-wrap">
                                                <FontAwesomeIcon icon={faStar}/>
                                                <FontAwesomeIcon icon={faStar}/>
                                                <FontAwesomeIcon icon={faStar}/>
                                                <FontAwesomeIcon icon={faStar}/>
                                                <FontAwesomeIcon icon={faStar}/>
                                            </div>
                                            <div className="testimonial">
                                                {t.content()}
                                                <div className="row">
                                                    <div className="col end">
                                                    <span className="name">- {t.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                </div>
                                )
                           })
                       }
                       
                   </Content>
                </Layout>
            )
        }
    </Location>
)